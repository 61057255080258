import React from 'react'
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import img_part1 from "../assets/images/KB-Horizontal-Tagline.png";
import img_part2 from "../assets/images/Profectus_Capital_Niyogin_Website.png";
import img_part3 from "../assets/images/Lendingkart_Website_Logo.png";
import img_part4 from "../assets/images/LT-Finance_Niyogin_Website.png";
import img_part5 from "../assets/images/EFL_Niyogin_Website.png";
import img_part6 from "../assets/images/Fatak_Pay_Logo_NFL.png";
import img_part7 from "../assets/images/NFL_IIFL_Logo.png";
import img_part8 from "../assets/images/Arthmate_Logo_NFL.png";
import img_part9 from "../assets/images/Metafin_Logo_NFL.png";
import img_part10 from "../assets/images/BillCut_Logo_Niyogin_Fintech_Limited.png";
import img_part11 from "../assets/images/Finsall-Logo.png";
import img_part12 from "../assets/images/Okcredit_1.jpg"


export default function PartnersLogo({title,cls}) {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay:true,
        arrows:true,
        responsive: [
            {
              breakpoint: 1150,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
              },
            },
            {
                breakpoint: 850,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                },
              },
        ]
      };


  return (
    <div className={`PartnersLogo_main_container ` + cls}>
        <div className='logo_and_titl_part'>
            <div className='part_title'  data-aos="fade-down" 
     data-aos-easing="ease-in-sine" data-aos-duration="1000">
                {title?title:"PARTNERS"}
            </div>
            <div className='silderr_partner_logo'  data-aos="fade-up" 
     data-aos-easing="ease-in-sine" data-aos-duration="1000">
                <div className='slider_part_class'>
                <Slider {...settings}>

                
                    <div className="part_logo_img_div"><img src={img_part1} alt="logo_partner" /></div>
                    <div className="part_logo_img_div"><img src={img_part2} alt="logo_partner" /></div>
                    <div className="part_logo_img_div"><img src={img_part3} alt="logo_partner" /></div>
                    <div className="part_logo_img_div"><img src={img_part4} alt="logo_partner" /></div>
                    <div className="part_logo_img_div"><img src={img_part5} alt="logo_partner" /></div>
                    <div className="part_logo_img_div"><img src={img_part6} alt="logo_partner" /></div>
                    <div className="part_logo_img_div"><img src={img_part7} alt="logo_partner" /></div>
                    <div className="part_logo_img_div"><img src={img_part8} alt="logo_partner" /></div>
                    <div className="part_logo_img_div"><img src={img_part9} alt="logo_partner" /></div>
                    <div className="part_logo_img_div"><img src={img_part10} alt="logo_partner" /></div>
                    <div className="part_logo_img_div"><img src={img_part11} alt="logo_partner" /></div>
                    <div className="part_logo_img_div"><img src={img_part12} alt="logo_partner" /></div>
                </Slider>
                </div>
            </div>
        </div>
    </div>
  )
}
